import React, { useEffect, useRef, useState } from "react"
import { formatDollar, formatDollarWithSymbol, formatPercent } from "../utils/formats"
import CurrencyInput from 'react-currency-input-field'
import apiClient from '../utils/apiClient'
import Modal from '../components/atom/Modal'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { set } from "react-hook-form"


const SORT_FIELDS = {
  unit_type: 'unit_type',
  unit_style: 'unit_style',
  physical_occupancy: 'physical_occupancy',
  vacant_unit: 'available_units',
  today_street_rate: 'std_rate',
  recommended_street_rate: 'new_std_rate',
  difference_amount: 'difference_amount',
  difference_percent: 'difference_percent',
}

// Function to format the date
const formatDate = (date) => {
  return dayjs(date).format('MM/DD/YYYY'); // Adjust the format as needed
};

const UnitTypeRow = ({ data, handleChange }) => {
  const [dataState, setDataState] = useState(data);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [clickFromClockIcon, setClickFromClockIcon] = useState(false);
  const [modalIsOpenLockScheduleConfirm, setModalIsOpenLockScheduleConfirm] = useState(false);
  const [modalIsOpenLockSchedule, setModalIsOpenLockSchedule] = useState(false);
  const [datePickerValue, setDatePickerValue] = useState(null);
  const [unitTypeCategoryForm, setUnitTypeCategoryForm] = useState({
    id: null,
    exception: null,
    guide: null,
    variance: null
  });

  const handleNewRateChange = (newUnit, value) => {
    const temp = { ...newUnit, new_std_rate: value }
    setDataState(temp)
    handleChange(temp)
  }

  const handleLockUnitRate = (newUnit, value, canSetLockExpirationDate = false) => {
    if (clickFromClockIcon) {
      setModalIsOpenLockScheduleConfirm(false)
      setModalIsOpenLockSchedule(false)
      setDatePickerValue(null)
      return setClickFromClockIcon(false)
    }

    const temp = { ...newUnit, locked: value }

    if (canSetLockExpirationDate) {
      const date = datePickerValue ? formatDate(datePickerValue) + ' 12:00:00' : null;
      temp.lock_expiration_date = date;
    }
    
    setDataState(temp)
    handleChange(temp)
    setModalIsOpenLockScheduleConfirm(false)
    setModalIsOpenLockSchedule(false)
    setDatePickerValue(null)
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setUnitTypeCategoryForm({ id: null, exception: null, guide: null, variance: null })
  }

  const closeModal2 = () => {
    setModalIsOpen2(false);
  }

  const saveUnitTypeCategoryValue = () => {
    unitTypeCategoryForm.exception = true;
    unitTypeCategoryForm.variance = parseFloat(unitTypeCategoryForm.variance) / 100
    apiClient.put(`/street_rates/unit-type/${unitTypeCategoryForm?.id}`, unitTypeCategoryForm).then(function (response) {
      setModalIsOpen(false);
      const temp = {...dataState, exception: true, guide: unitTypeCategoryForm.guide, variance: unitTypeCategoryForm.variance}
      setDataState(temp)
      handleChange(temp)
    }).catch(function (error) {
      console.log(error)
      setModalIsOpen(false);
    })

    setUnitTypeCategoryForm({ id: null, exception: null, guide: null, variance: null })
  }

  const removeSecondaryUnitType = (id) => {
    apiClient.put(`/street_rates/unit-type/${id}`, {
      exception: null,
      guide: null,
      variance: null
    }).then(function (response) {
      setModalIsOpen2(false);
      const temp = {...dataState, exception: null, guide: null, variance: null}
      setDataState(temp)
      handleChange(temp)
    }).catch(function (error) {
      console.log(error)
      setModalIsOpen2(false);
    })
  }

  const getSecondaryUnitTypeLabel = (guide) => {
    switch (guide) {
      case 'drive_up':
        return 'Drive Up'
      case 'climate_controlled':
        return 'Climate Controlled'
      case 'parking':
        return 'Parking'
      default:
        return ''
    }
  }

  const unitTypeCategory = `${getSecondaryUnitTypeLabel(dataState.guide)} ${parseFloat(dataState?.variance) > 0 ? ('+' + dataState.variance * 100) : dataState.variance* 100}%`;

  return (
    <tr key={"unit" + dataState.id}>
      <td><div className="flex items-center justify-between">
        <div><p>{dataState.unit_type}</p>
          {dataState?.exception && (
            <p style={{ color: '#2196F3' }}>{unitTypeCategory}</p>
          )}
        </div>
        {dataState?.exception && (
          <img src={`/assets/images/cancel-icon.svg`} alt="Cancel Icon" className="cursor-pointer" onClick={() => setModalIsOpen2(true)} />
        )}
        {!dataState?.exception &&
          <img src={`/assets/images/plus-icon.svg`} alt="Add Icon" style={{ marginRight: '-5px'}} className="cursor-pointer" onClick={() => {
            setModalIsOpen(true)
            setUnitTypeCategoryForm({ ...unitTypeCategoryForm, id: dataState.ut_id })
          }} />
        }
        <Modal
          isOpen={modalIsOpen}
        >
          <h2 className="font-bold text-center">Please select the unit category you’d like to anchor this unit type to:</h2>
          <div className="action mt-4 text-center">
            <button
              type="button"
              className={"border border-radius-lg text-sm mr-2 " + (unitTypeCategoryForm.guide === 'drive_up' ? 'bg-primary text-white' : 'border-black text-black')}
              onClick={() => {
                setUnitTypeCategoryForm({ ...unitTypeCategoryForm, guide: 'drive_up' })
              }}
            >
              Drive Up
            </button>
            <button
              type="button"
              className={"border border-radius-lg text-sm mr-2 " + (unitTypeCategoryForm.guide === 'climate_controlled' ? 'bg-primary text-white' : 'border-black text-black')}
              onClick={() => {
                setUnitTypeCategoryForm({ ...unitTypeCategoryForm, guide: 'climate_controlled' })
              }}
            >
              Climate Controlled
            </button>
            <button
              type="button"
              className={"border border-radius-lg text-sm " + (unitTypeCategoryForm.guide === 'parking' ? 'bg-primary text-white' : 'border-black text-black')}
              onClick={() => {
                setUnitTypeCategoryForm({ ...unitTypeCategoryForm, guide: 'parking' })
              }}
            >
              Parking
            </button>
          </div>

          <h2 className="font-bold text-center mt-10">Input your adjustment percentage:</h2>
          <div className="action mt-4 text-center">
            <CurrencyInput
              className="w-20"
              suffix="%"
              placeholder="5%"
              disableGroupSeparators={true}
              decimalsLimit={2}
              disabled={!unitTypeCategoryForm?.guide}
              value={dataState?.variance}
              name="variance"
              onValueChange={(value) => {
                setUnitTypeCategoryForm({ ...unitTypeCategoryForm, variance: value })
              }}
            />
          </div>

          <div class="action mt-10 text-center">
            <button
              type="button"
              className="border border-radius-lg border-default uppercase text-default text-sm mr-4"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="button"
              disabled={!unitTypeCategoryForm?.variance}
              className="border border-radius-lg border-primary uppercase text-primary text-sm"
              onClick={saveUnitTypeCategoryValue}
            >
              Save
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={modalIsOpen2}
        >
          <h2 className="font-bold text-center">Are you sure you want to remove this unit type anchor?</h2>

          <div className="action mt-10 text-center">
            <button
              type="button"
              className="border border-radius-lg border-default uppercase text-default text-sm mr-4"
              onClick={closeModal2}
            >
              NO
            </button>
            <button
              type="button"
              className="border border-radius-lg border-primary uppercase text-primary text-sm"
              onClick={() => removeSecondaryUnitType(dataState.ut_id)}
            >
              YES
            </button>
          </div>
        </Modal>
      </div></td>
      <td style={{ maxWidth: 150, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} title={dataState.unit_style}>
        {dataState.unit_style}
      </td>
      <td>{dataState.physical_occupancy?.toFixed(2)}%</td>
      <td>{dataState.available_units}</td>
      <td>${formatDollar(dataState.std_rate)}</td>
      <td>${dataState.locked ? '-' : formatDollar(dataState.new_std_rate)}</td>
      <td>
        <span className="px-6 py-2 bg-green-200 rounded rounded-md">
          {dataState.locked ? '$-' : formatDollarWithSymbol(dataState.difference_amount)}
        </span>
      </td>
      <td>{dataState.locked ? '-%' : formatPercent(dataState.difference_percent)}</td>
        <td className="w-24">
            <div style={{display: 'flex', alignItems: 'center'}}>
                {
                    dataState.locked
                        ? <div className="w-24 px-4 py-2 border border-gray-700 rounded-md">$-</div>
                        : <CurrencyInput
                            className="w-24"
                            prefix="$"
                            disableGroupSeparators={true}
                            defaultValue={formatDollar(dataState.new_std_rate)}
                            decimalsLimit={2}
                            onValueChange={(value) => handleNewRateChange(dataState, value)}
                        />
                }
                {
                    data.fc_value_pricing === 'on' && (
                        <>
                            <span
                                className="text-gray-400 ml-1">{data.locked ? '$-' : '$' + (parseFloat(data.new_std_rate) * 1.07).toFixed(2)}</span>
                            <span
                                className="text-gray-400 ml-1">{data.locked ? '$-' : '$' + (parseFloat(data.new_std_rate) * 1.14).toFixed(2)}</span>
                        </>
                    )
                }
            </div>
        </td>
        <td align="center">
          <div style={{display: 'flex', alignItems: 'center'}}>
            <input type="checkbox" checked={dataState.locked} onChange={(e) => {
              if (e.target.checked) {
                setModalIsOpenLockScheduleConfirm(true)
              } else {
                handleLockUnitRate(dataState, e.target.checked)
              }
            }}/>
            {dataState.locked && dataState.lock_expiration_date && <img
                src="/assets/images/time-icon.svg"
                className="h-4 ml-2"
                alt="time"
                onClick={() => {
                  setDatePickerValue(dayjs())
                  setClickFromClockIcon(true)
                  setModalIsOpenLockSchedule(true)
                }}
              />}
          </div>
          <Modal isOpen={modalIsOpenLockScheduleConfirm}>
              <h2 className="font-bold text-center">Schedule expiration?</h2>
              <div class="action mt-10 text-center">
                <button
                  type="button"
                  className="border border-radius-lg border-default uppercase text-default text-sm mr-4"
                  onClick={() => handleLockUnitRate(dataState, true)}
                >
                  NO
                </button>
                <button
                  type="button"
                  className="border border-radius-lg border-primary uppercase text-primary text-sm"
                  onClick={() => {
                    setModalIsOpenLockScheduleConfirm(false)
                    setModalIsOpenLockSchedule(true)
                  }}
                >
                  YES
                </button>
              </div>
           </Modal>
           <Modal isOpen={modalIsOpenLockSchedule}>
              <h2 className="font-bold text-center">Select date:</h2>
              <div class="action mt-10 text-center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker value={dataState?.lock_expiration_date ? dayjs(dataState?.lock_expiration_date) : null} onChange={(newValue) => setDatePickerValue(newValue)}/>
                </LocalizationProvider>
              </div>
              <div class="action mt-10 text-center">
                <button
                  type="button"
                  className="border border-radius-lg border-default uppercase text-default text-sm mr-4"
                  onClick={() => handleLockUnitRate(dataState, false)}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="border border-radius-lg border-primary uppercase text-primary text-sm"
                  onClick={() => handleLockUnitRate(dataState, true, true)}
                >
                  OK
                </button>
              </div>
           </Modal>
        </td>
    </tr>
  )
}

const DropdownFilter = ({units, label, filterUnits, sortUnits}) => {
    const uniqueUnitTypes = [...new Set(units.map(unit => unit.unit_style?.replace(/\s*,\s*/g, ',').split(',')).flat())];
    const [selectedStyles, setSelectedStyles] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [dropdownStyle, setDropdownStyle] = useState({});

    const toggleDropdown = () => setIsOpen(!isOpen)

    const closeDropdown = (event) => {
        if (!event.target.closest('.dropdown-container')) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        window.addEventListener('click', closeDropdown)
        return () => window.removeEventListener('click', closeDropdown)
    }, [])

    useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const dropdown = dropdownRef.current;
      const th = dropdown.parentElement;
      const thRect = th.getBoundingClientRect();
      const dropdownRect = dropdown.getBoundingClientRect();

      const spaceBelow = window.innerHeight - thRect.bottom;
      const spaceAbove = thRect.top;

      if (spaceBelow < dropdownRect.height && spaceAbove > dropdownRect.height) {
        setDropdownStyle({ top: 'auto', bottom: '100%' });
      } else {
        setDropdownStyle({ top: '85%', bottom: 'auto' });
      }
    }
  }, [isOpen]);

  const handleStyleChange = (style) => {
    const newSelectedStyles = selectedStyles.includes(style)
      ? selectedStyles.filter(s => s !== style)
      : [...selectedStyles, style];

    setSelectedStyles(newSelectedStyles);
    filterUnits(newSelectedStyles);
  };

  return (
    <th className="dropdown-container relative cursor-pointer select-none" onClick={toggleDropdown} >
      <div className="inline-block text-left w-36">
        <div>
          <div>
            {label}
          </div>
        </div>
        {isOpen && (
          <div
            className="origin-top-right absolute left-0 mt-2 w-[20rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
            role="menu"
            aria-orientation="vertical"
            style={{ ...dropdownStyle }}
            ref={dropdownRef}
          >
            <div
              className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 flex items-center gap-2"
              onClick={() => sortUnits(SORT_FIELDS.unit_style, 'asc')}
            >
              <span className="material-symbols-rounded w-1/12">arrow_upward</span>
              <p className="font-normal text-sm normal-case">Sort Ascending</p>
            </div>
            <div
              className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 flex items-center gap-2 border-b border-slate-300"
              onClick={() => sortUnits(SORT_FIELDS.unit_style, 'desc')}
            >
              <span className="material-symbols-rounded w-1/12">arrow_downward</span>
              <p className="font-normal text-sm normal-case">Sort Descending</p>
            </div>
            {uniqueUnitTypes.map(unit_stype => (
              <div
                className="block px-4 py-2 text-xs text-gray-700 hover:bg-gray-100 flex items-center gap-2"
                onClick={() => handleStyleChange(unit_stype)}
                key={unit_stype}
              >
                <input
                  type="checkbox"
                  className="cursor-pointer w-1/12"
                  value={unit_stype}
                  checked={selectedStyles.includes(unit_stype)}
                  onChange={() => handleStyleChange(unit_stype)}
                />
                <p className="font-normal text-sm normal-case">{unit_stype}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </th>
  )
}

const UnitTypeStatistics = ({ rows, handleChange }) => {
  const [filteredUnits, setFilteredUnits] = useState(rows);
  const [currentFilteredUnits, setCurrentFilteredUnits] = useState(rows);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const handleFilter = (unit_styles) => {
    let updatedFilteredUnits;

    if (unit_styles.length === 0) {
      updatedFilteredUnits = rows;
      setCurrentFilteredUnits(updatedFilteredUnits);
    } else {
      updatedFilteredUnits = rows.filter(unit =>
        unit_styles.some(style => unit.unit_style.includes(style))
      );
      setCurrentFilteredUnits(updatedFilteredUnits);
    }

    const sortedUnits = !!sortConfig.direction ? sortUnits(updatedFilteredUnits, sortConfig.key, sortConfig.direction) : updatedFilteredUnits;
    setFilteredUnits(sortedUnits);
  };

  const sortUnits = (units, key, direction) => {
    return [...units].sort((a, b) => {
      if (a[key] === null && b[key] === null) {
        return 0;
      }
      if (a[key] === null) {
        return 1;
      }
      if (b[key] === null) {
        return -1;
      }

      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (key, cus_direction = '') => {
    let direction = cus_direction || 'asc';
    if (!cus_direction && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    if (!cus_direction && sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = null;
    }

    const sortedUnits = !!direction ? sortUnits(filteredUnits, key, direction) : currentFilteredUnits
    setFilteredUnits(sortedUnits);
    setSortConfig({ key, direction });
  }

  const renderSortIcon = (key) => {
    const className = sortConfig.key !== key ? '' :
      sortConfig.direction === 'asc' ? '-up' :
        sortConfig.direction === 'desc' ? '-down' : '';

    return (
      <img src={`/assets/images/sort${className}.svg`} alt={`Sort Icon${className}`} className="w-[1rem] p-1 inline" />
    )
  }

  return (
    <div className="flex items-center">
      <table className="w-full table-unit-type-statistics">
        <thead className="text-gray-600">
          <tr className="uppercase text-left">
            <th
              onClick={() => handleSort(SORT_FIELDS.unit_type)}
              className="cursor-pointer select-none" style={{ minWidth: 250 }}
            >
              Unit Type &nbsp;
              {renderSortIcon(SORT_FIELDS.unit_type)}
            </th>
            <DropdownFilter
              units={rows}
              label={(
                <div>
                  Attributes
                  &nbsp;
                  {renderSortIcon(SORT_FIELDS.unit_style)}
                </div>
              )}
              filterUnits={handleFilter}
              sortUnits={handleSort}
            />
            <th
              onClick={() => handleSort(SORT_FIELDS.physical_occupancy)}
              className="cursor-pointer select-none"
            >
              Physical
              <br />
              Occupancy
              &nbsp;
              {renderSortIcon(SORT_FIELDS.physical_occupancy)}
            </th>
            <th
              onClick={() => handleSort(SORT_FIELDS.vacant_unit)}
              className="cursor-pointer select-none"
            >
              Vacant
              <br />
              Units
              &nbsp;
              {renderSortIcon(SORT_FIELDS.vacant_unit)}
            </th>
            <th
              onClick={() => handleSort(SORT_FIELDS.today_street_rate)}
              className="cursor-pointer select-none"
            >
              Today's
              <br />
              Street
              <br />
              Rate
              &nbsp;
              {renderSortIcon(SORT_FIELDS.today_street_rate)}
            </th>
            <th
              onClick={() => handleSort(SORT_FIELDS.recommended_street_rate)}
              className="cursor-pointer select-none"
            >
              Recommended
              <br />
              Street Rate
              &nbsp;
              {renderSortIcon(SORT_FIELDS.recommended_street_rate)}
            </th>
            <th
              onClick={() => handleSort(SORT_FIELDS.difference_amount)}
              className="cursor-pointer select-none"
            >
              Difference $
              &nbsp;
              {renderSortIcon(SORT_FIELDS.difference_amount)}
            </th>
            <th
              onClick={() => handleSort(SORT_FIELDS.difference_percent)}
              className="cursor-pointer select-none"
            >
              Difference %
              &nbsp;
              {renderSortIcon(SORT_FIELDS.difference_percent)}
            </th>
            <th>New Rate</th>
            <th align="center">Lock Rate</th>
          </tr>
        </thead>
        <tbody>
          {filteredUnits && filteredUnits.map((unit) => (
            <UnitTypeRow
              data={unit}
              handleChange={handleChange}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default UnitTypeStatistics
