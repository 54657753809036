import React, { useState, useContext, useEffect, useRef } from "react";
import SelectGroup from "../components/atom/SelectGroup";
import { useParams } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import CurrencyInput from "react-currency-input-field";
import TagsInput from "../components/TagsInput";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "../components/Tooltip";
import {
  STRATEGY_OPTIONS,
  VALUE_PRICING_OPTIONS,
  FREQUENCY_OPTIONS,
  WEEKDAYS,
  CADENCE_OPTIONS,
  convertToNumber,
  convertPercentageToDecimal,
  convertDecimalToPercentage,
} from "../utils/config";
import { Controller, useForm } from "react-hook-form";
import { cn } from "../utils/cn";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Select from "../components/atom/Select";
import facilityProfileService from "../services/facility_profile";
import portfolioService from "../services/portfolio";
import { cronJobService } from "../services/cron_job";
import unitService from "../services/unit";
import FacilitySelect from "../components/atom/FacilitySelect";
import facilityService from "../services/facility_profile";

const strategyOptions = STRATEGY_OPTIONS.map((item) => item.label);
const valuePricingOptions = VALUE_PRICING_OPTIONS.map((item) => item.label);

dayjs.extend(utc);

const defaultValues = {
  // street-rate-update-schedule
  frequency: FREQUENCY_OPTIONS[0],
  weekday: WEEKDAYS[0],
  timeOfDay: "00:00",
  dayOfMonth: 1,
  emails: [],

  // Existing Customer Rate Increase Timing
  cadence: CADENCE_OPTIONS[0],
  frequencyTiming: 0,
  totalRevenueIncreaseTarget: null,
  // Rate Increase Criteria
  maxDollarIncrease: null,
  minDollarIncrease: null,
  maxPercentIncrease: null,
  minPercentIncrease: null,
  storeOccupancyThreshold: null,
  timeSinceLastIncrease: null,
  timeSinceMoveIn: null,
  limitAboveStreetRate: null,
  maxMoveOutProbability: null,
};

const Settings = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, auth } = useContext(AuthContext);
  const customer_id = auth?.user?.id;
  const portfolio_id = auth?.user?.portfolio_id;

  const [selectedFacility, setSelectedFacility] = useState(null);
  const [selectedFacilityOption, setSelectedFacilityOption] = useState(null);
  const [isPortfolioSetting, setIsPortfolioSetting] = useState(true);

  const [isLoaded, setIsLoaded] = React.useState(false);
  const [strategy, setStrategy] = React.useState();
  const [valuePricing, setValuePricing] = React.useState('Off');
  const [isMultipleStrategies, setIsMultipleStrategies] = React.useState(false);
  const { control, handleSubmit, watch, register, setValue, reset, getValues } =
    useForm({ defaultValues });

  const frequency = watch("frequency");
  const weekday = watch("weekday");
  const timeOfDay = watch("timeOfDay");
  const emails = watch("emails");

  useEffect(() => {
    const loadSelectedFacility = async (id) => {
      if (!id) return;
      const facility = await facilityService.fetchFacility(id);
      if (!facility) return;
      setSelectedFacility(facility);
      setSelectedFacilityOption({
        value: facility.id,
        label: facility.facility_name,
      });
      setIsPortfolioSetting(facility?.id === undefined);
    };
    loadSelectedFacility(id);
  }, [id]);

  useEffect(() => {
    if (!customer_id) return;
    getCronjobData();
    getPortfolioStrategy();
    getPortfolioValuePricing();
  }, [customer_id, isPortfolioSetting, selectedFacility?.id]);

  useEffect(() => {
    if (!portfolio_id) return;
    if (isPortfolioSetting) {
      getPortfolioEcriSettings();
    } else {
      getFacilityEcriSettings();
    }
  }, [portfolio_id, isPortfolioSetting, selectedFacility?.id]);

  async function updateCronjob(data) {
    const day_of_week = WEEKDAYS.indexOf(weekday) % WEEKDAYS.length || 0;
    const updateParams = { customer_id, frequency, emails };

    if (frequency === FREQUENCY_OPTIONS[1]) {
      updateParams.day_of_week = day_of_week;
    }

    if (frequency === FREQUENCY_OPTIONS[2]) {
      updateParams.day_of_month = data.dayOfMonth;
    }

    // Assuming today's date for the context
    const today = dayjs().format("YYYY-MM-DD");
    // Combine today's date with the timeOfDay
    const datetime = dayjs(`${today}T${timeOfDay}`);
    // Convert to UTC and format
    const utcTime = datetime.utc().format("HH:mm");

    const [hour, minute] = utcTime.split(":");
    updateParams.hour = hour;
    updateParams.minute = minute;

    await cronJobService.updateCronJob(updateParams);
  }

  async function getCronjobData() {
    try {
      const response = await cronJobService.fetchCronJob(customer_id);

      let {
        frequency,
        minute,
        hour,
        day_of_week = 0,
        day_of_month = 1,
        emails = [],
      } = response?.data?.result || {};

      if (
        hour !== null &&
        hour !== undefined &&
        hour !== "" &&
        parseInt(hour) < 10
      ) {
        hour = `0${hour}`;
      }

      if (
        minute !== null &&
        minute !== undefined &&
        minute !== "" &&
        parseInt(minute) < 10
      ) {
        minute = `0${minute}`;
      }

      let timeOfDay = `${hour}:${minute}`;

      // Get the current UTC time in 'YYYY-MM-DD' format
      const utcDate = dayjs().utc().format("YYYY-MM-DD");
      // Combine today's date with the timeOfDay
      const datetime = dayjs(`${utcDate}T${timeOfDay}:00Z`);
      // Convert UTC datetime to local time
      timeOfDay = dayjs(datetime).local().format("HH:mm");

      const formValues = getValues();
      reset({
        ...formValues,
        frequency,
        weekday: WEEKDAYS[day_of_week],
        timeOfDay,
        dayOfMonth: day_of_month,
        emails,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoaded(true);
    }
  }

  async function getPortfolioStrategy() {
    if (!isPortfolioSetting && !selectedFacility?.id) {
      return;
    }
    const response = await facilityProfileService.getPortfolioStrategies(
      customer_id
    );

    setStrategy(undefined);
    const data = response?.data?.result || [];
    const listStrategies = [
      ...new Set(data.map((i) => i.street_rate_strategy).filter((i) => i)),
    ];

    if (isPortfolioSetting) {
      const isMultipleStrategies = listStrategies.length > 1;
      setIsMultipleStrategies(isMultipleStrategies);
      if (!isMultipleStrategies) {
        const option = STRATEGY_OPTIONS.find(
          (i) => i.value === listStrategies[0]
        );
        setStrategy(option?.label);
      }
    } else {
      const option = STRATEGY_OPTIONS.find(
        (i) =>
          i.value ===
          data.find((i) => i.id === parseInt(selectedFacility.id))
            ?.street_rate_strategy
      );
      setStrategy(option?.label);
    }
  }

  async function getPortfolioValuePricing() {
    if (!isPortfolioSetting && !selectedFacility?.id) {
      return;
    }
    const response = await facilityProfileService.getPortfolioValuePricing(
      customer_id
    );

    const data = response?.data?.result || [];
    const listValuePricing = [
      ...new Set(data.map((i) => i.value_pricing).filter((i) => i)),
    ];

    if (isPortfolioSetting) {
      const option = VALUE_PRICING_OPTIONS.find(
        (i) => i.value === listValuePricing[0]
      );
      setValuePricing(option?.label);
    } else {
      const option = VALUE_PRICING_OPTIONS.find(
        (i) =>
          i.value ===
          data.find((i) => i.id === parseInt(selectedFacility.id))
            ?.value_pricing
      );
      setValuePricing(option?.label);
    }
  }

  async function getPortfolioEcriSettings() {
    const portfolioData =
      (await portfolioService.getPortfolioDetail(portfolio_id)) || {};
    const ecriSettings = portfolioData.ecri_settings || {};
    ecriSettings.totalRevenueIncreaseTarget = convertDecimalToPercentage(
      ecriSettings.totalRevenueIncreaseTarget
    );
    ecriSettings.maxDollarIncrease = convertToNumber(ecriSettings.maxDollarIncrease);
    ecriSettings.minDollarIncrease = convertToNumber(ecriSettings.minDollarIncrease);
    ecriSettings.maxPercentIncrease = convertDecimalToPercentage(
      ecriSettings.maxPercentIncrease
    );
    ecriSettings.minPercentIncrease = convertDecimalToPercentage(
      ecriSettings.minPercentIncrease
    );
    ecriSettings.storeOccupancyThreshold = convertDecimalToPercentage(
      ecriSettings.storeOccupancyThreshold
    );
    ecriSettings.timeSinceLastIncrease = convertToNumber(ecriSettings.timeSinceLastIncrease);
    ecriSettings.timeSinceMoveIn = convertToNumber(ecriSettings.timeSinceMoveIn);
    ecriSettings.limitAboveStreetRate = convertToNumber(ecriSettings.limitAboveStreetRate);
    ecriSettings.maxMoveOutProbability = convertDecimalToPercentage(
      ecriSettings.maxMoveOutProbability
    );
    const formValues = getValues();
    reset({ ...formValues, ...ecriSettings });
  }

  async function getFacilityEcriSettings() {
    if (!selectedFacility?.id) return;

    const response = await facilityProfileService.getEcriSettings(
      selectedFacility.id
    );
    const facilityData = response.data?.result || {};
    const ecriSettings = facilityData.ecri_settings || {};
    ecriSettings.totalRevenueIncreaseTarget = convertDecimalToPercentage(
      ecriSettings.totalRevenueIncreaseTarget
    );
    ecriSettings.maxDollarIncrease = convertToNumber(ecriSettings.maxDollarIncrease);
    ecriSettings.minDollarIncrease = convertToNumber(ecriSettings.minDollarIncrease);
    ecriSettings.maxPercentIncrease = convertDecimalToPercentage(
      ecriSettings.maxPercentIncrease
    );
    ecriSettings.minPercentIncrease = convertDecimalToPercentage(
      ecriSettings.minPercentIncrease
    );
    ecriSettings.storeOccupancyThreshold = convertDecimalToPercentage(
      ecriSettings.storeOccupancyThreshold
    );
    ecriSettings.timeSinceLastIncrease = convertToNumber(ecriSettings.timeSinceLastIncrease);
    ecriSettings.timeSinceMoveIn = convertToNumber(ecriSettings.timeSinceMoveIn);
    ecriSettings.limitAboveStreetRate = convertToNumber(ecriSettings.limitAboveStreetRate);
    ecriSettings.maxMoveOutProbability = convertDecimalToPercentage(
      ecriSettings.maxMoveOutProbability
    );
    const formValues = getValues();
    reset({ ...formValues, ...ecriSettings });
  }

  const onSubmitForm = async (data) => {
    const ecriSettings = {
      // Existing Customer Rate Increase Timing
      totalRevenueIncreaseTarget: convertPercentageToDecimal(
        data.totalRevenueIncreaseTarget
      ),
      // Rate Increase Criteria
      maxDollarIncrease: convertToNumber(data.maxDollarIncrease),
      minDollarIncrease: convertToNumber(data.minDollarIncrease),
      maxPercentIncrease: convertPercentageToDecimal(data.maxPercentIncrease),
      minPercentIncrease: convertPercentageToDecimal(data.minPercentIncrease),
      storeOccupancyThreshold: convertPercentageToDecimal(
        data.storeOccupancyThreshold
      ),
      timeSinceLastIncrease: convertToNumber(data.timeSinceLastIncrease),
      timeSinceMoveIn: convertToNumber(data.timeSinceMoveIn),
      limitAboveStreetRate: convertToNumber(data.limitAboveStreetRate),
      maxMoveOutProbability: convertPercentageToDecimal(
        data.maxMoveOutProbability
      ),
    };

    if (isPortfolioSetting) {
      await portfolioService.saveEcriSettings(portfolio_id, ecriSettings);
    } else {
      await facilityProfileService.saveEcriSettings(
        selectedFacility.id,
        ecriSettings
      );
    }

    updateCronjob(data);
  };

  const onChangeStrategy = async (label) => {
    setStrategy(label);
    setIsMultipleStrategies(false);
    const value = STRATEGY_OPTIONS.find((i) => i.label === label)?.value;
    if (!value) {
      alert("Invalid strategy");
      return;
    }
    if (isPortfolioSetting) {
      await facilityProfileService.savePortfolioStrategies(customer_id, value);
    } else {
      await facilityProfileService.saveFacilityStrategies(
        selectedFacility.id,
        value
      );
    }
  };

  const onChangeValuePricing = async (label) => {
    setValuePricing(label);
    const value = VALUE_PRICING_OPTIONS.find((i) => i.label === label)?.value;
    if (!value) {
      alert("Invalid value pricing");
      return;
    }
    if (isPortfolioSetting) {
      await facilityProfileService.savePortfolioValuePricing(
        customer_id,
        value
      );
    } else {
      await facilityProfileService.saveFacilityValuePricing(
        selectedFacility.id,
        value
      );
    }
  };

  const handleChangeFacilityOption = async (selected) => {
    setSelectedFacilityOption(selected);
    if (selected.value) {
      navigate(`/settings/${selected.value}`);
    } else {
      navigate(`/settings`);
      setIsPortfolioSetting(true);
    }
  };

  const fileInputRef = useRef(null);

  const onPick = () => {
    fileInputRef.current.click();
  };

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      uploadUnitRanking(file);
      event.target.value = '';
    }
  };

  const uploadUnitRanking = async (file) => {
    if (!selectedFacility) {
      alert("No facility selected");
      return;
    }
    await unitService.uploadXLSX(file, selectedFacility.facility_id);
  };

  const downloadSampleXLSX = async () => {
    if (!selectedFacility) {
      alert("No facility selected");
      return;
    }
    await unitService.downloadSampleXLSX(selectedFacility.facility_id);
  };

  const onExport = async () => {
    if (!selectedFacility) {
      alert("No facility selected");
      return;
    }
    await unitService.exportXLSX(selectedFacility.facility_id);
  };

  const handleToggleStatus = async (value) => {
    if (!selectedFacility) {
      alert("No facility selected");
      return;
    }
    const currentVal =
      selectedFacility?.status === "enabled" ? "Enabled" : "Disabled";

    if (currentVal === value) {
      return;
    }
    try {
      await facilityProfileService.toggleFacilityStatus(selectedFacility.id);
      setSelectedFacility({
        ...selectedFacility,
        status: value === "Enabled" ? "enabled" : "disabled",
      });
    } catch (error) {
      console.log("error", error.toString());
      alert("Failed to update facility status:", error.toString());
    }
  };

  if (!isAuthenticated) navigate("/sign-in");

  return (
    <Dashboard>
      <form key={1} onSubmit={handleSubmit(onSubmitForm)} noValidate>
        <div className="w-full rounded-2xl border-2 border-gray-200 px-8 py-8 bg-white mb-4 flex flex-col gap-8">
          <div>
            <FacilitySelect
              className="w-96"
              defaultValue={{ value: undefined, label: "Portfolio" }}
              value={selectedFacilityOption}
              onChange={handleChangeFacilityOption}
            />
          </div>

          <hr />

          {!isPortfolioSetting && (
            <div>
              <h1 className="font-bold text-gray-500">Status</h1>
              <div className="rounded-gray-outlined-box">
                <SelectGroup
                  options={["Enabled", "Disabled"]}
                  selected={
                    selectedFacility?.status === "enabled"
                      ? "Enabled"
                      : "Disabled"
                  }
                  onChange={handleToggleStatus}
                />
              </div>
            </div>
          )}

          <div>
            <h1 className="font-bold text-gray-500">Street Rate Strategy</h1>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <img
                  src="/assets/images/light-bulb.png"
                  className="h-5"
                  alt="Light Bulb Icon"
                />
                <h1 className="font-bold mx-2">Street Rate Strategy</h1>
                <Tooltip title="Your selection will apply to all facilities.">
                  <img
                    src="/assets/images/danger-circle.png"
                    className="h-4"
                    alt="question"
                  />
                </Tooltip>
              </div>
              {/* multiple */}
              <div className="flex gap-4">
                <SelectGroup
                  options={strategyOptions}
                  selected={strategy}
                  onChange={onChangeStrategy}
                />
                {isPortfolioSetting && (
                  <button
                    className={cn(
                      `select-group`,
                      isMultipleStrategies ? "active" : ""
                    )}
                    type="button"
                    onClick={() => {}}
                  >
                    Multiple
                  </button>
                )}
              </div>
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <img
                  src="/assets/images/light-bulb.png"
                  className="h-5"
                  alt="Light Bulb Icon"
                />
                <h1 className="font-bold mx-2">Value Pricing</h1>
                <Tooltip title="Enable tiered pricing. Note: Your website must support this functionality.">
                  <img
                    src="/assets/images/danger-circle.png"
                    className="h-4"
                    alt="question"
                  />
                </Tooltip>
              </div>
              {/* multiple */}
              <div className="flex gap-4">
                <SelectGroup
                  options={valuePricingOptions.filter((i) => !isPortfolioSetting ? i !== 'Multiple': i)}
                  selected={valuePricing}
                  onChange={onChangeValuePricing}
                />
              </div>
            </div>

            {!isPortfolioSetting && (
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <img
                  src="/assets/images/light-bulb.png"
                  className="h-5"
                  alt="Light Bulb Icon"
                />
                <h1 className="font-bold mx-2">Unit Ranking Upload</h1>
                <Tooltip title="Unit Ranking Upload.">
                  <img
                    src="/assets/images/danger-circle.png"
                    className="h-4"
                    alt="question"
                  />
                </Tooltip>
              </div>
              <div className="flex flex-col gap-4">
                <div>
                  <button
                    className="max-w-80 select-group"
                    type="button"
                    onClick={onPick}
                  >
                  Click Here To Upload Your Unit Ranking
                </button>
                  <button
                    type="button"
                    onClick={downloadSampleXLSX}
                    className="link link-primary"
                  >
                    Download Sample XLSX
                  </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".xlsx"
                  onChange={onFileChange}
                />
                </div>
                <button
                  className="max-w-80 select-group"
                  type="button"
                  onClick={onExport}
                >
                  Click Here To Export Unit Ranking
                </button>
              </div>
            </div>
            )}
          </div>

          {isPortfolioSetting && (
            <div>
              <h1 className="font-bold text-gray-500">
                Street Rate Update Strategy
              </h1>
              <div className="rounded-gray-outlined-box">
                <div className="flex items-center w-96">
                  <img
                    src="/assets/images/audio-wave.png"
                    className="h-5"
                    alt="Light Bulb Icon"
                  />
                  <h1 className="font-bold mx-2">Frequency</h1>
                </div>
                <Controller
                  name="frequency"
                  control={control}
                  render={({ field }) => (
                    <SelectGroup
                      {...field}
                      options={FREQUENCY_OPTIONS}
                      selected={field.value}
                    />
                  )}
                />
              </div>
              {frequency === FREQUENCY_OPTIONS[1] && (
                <div className="rounded-gray-outlined-box">
                  <div className="flex items-center w-96">
                    <img
                      src="/assets/images/light-bulb.png"
                      className="h-5"
                      alt="Light Bulb Icon"
                    />
                    <h1 className="font-bold mx-2">Day Of Week</h1>
                  </div>

                  <Controller
                    name="weekday"
                    control={control}
                    render={({ field }) => (
                      <SelectGroup
                        {...field}
                        options={WEEKDAYS}
                        selected={field.value}
                      />
                    )}
                  />
                </div>
              )}
              {frequency === FREQUENCY_OPTIONS[2] && (
                <div className="rounded-gray-outlined-box">
                  <div className="flex items-center w-96">
                    <img
                      src="/assets/images/light-bulb.png"
                      className="h-5"
                      alt="Light Bulb Icon"
                    />
                    <h1 className="font-bold mx-2">Day Of Month</h1>
                  </div>
                  <Controller
                    name="dayOfMonth"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          className="w-48 border border-gray-700"
                          selected={field.value}
                          options={Array.from({ length: 31 }, (_, i) => i + 1)}
                        />
                      );
                    }}
                  />
                </div>
              )}
              <div className="rounded-gray-outlined-box">
                <div className="flex items-center w-96">
                  <img
                    src="/assets/images/clock.png"
                    className="h-5"
                    alt="Light Bulb Icon"
                  />
                  <h1 className="font-bold mx-2">Time Of Day</h1>
                </div>
                <input
                  type="time"
                  className="w-48 border border-gray-700"
                  {...register("timeOfDay")}
                />
              </div>
              <div className="rounded-gray-outlined-box">
                <div className="flex w-full">
                  <div className="w-23 flex items-center">
                    <img
                      src="/assets/images/outline-email.png"
                      className="h-5"
                      alt="Light Bulb Icon"
                    />
                    <h1 className="font-bold mx-2">Notification Email</h1>
                    <Tooltip title="Hit ENTER to add a new email.">
                      <img
                        src="/assets/images/danger-circle.png"
                        className="h-4"
                        alt=""
                      />
                    </Tooltip>
                  </div>
                  <div className="px-4 py-2">
                    <Controller
                      name="emails"
                      control={control}
                      render={({ field }) => (
                        <TagsInput {...field} placeholder="Email" />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div>
            <div className="flex items-center w-96">
              <h1 className="font-bold text-gray-500 mr-3">Average Percent Increase</h1>
              <Tooltip title="The average percentage increase of in-place tenant rates you wish to achieve.">
                <span className="material-symbols-rounded text-xl text-primary">
                  help
                </span>
              </Tooltip>
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">%</span>
                <h1 className="font-bold mx-2">
                  Total Revenue Increase Target
                </h1>
                <Tooltip title="Total Revenue Increase percentage across your entire portfolio. Recommended range: 0.5-2%">
                  <span className="material-symbols-rounded text-xl text-primary">
                    help
                  </span>
                </Tooltip>
              </div>

              <Controller
                name="totalRevenueIncreaseTarget"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      suffix="%"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div>
            <h1 className="font-bold text-gray-500">Rate Increase Criteria</h1>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">$</span>
                <h1 className="font-bold mx-2">Max Dollar Increase</h1>
                <Tooltip title="The maximum incremental dollar increase any one tenant may receive.">
                  <span className="material-symbols-rounded text-xl text-primary">
                    help
                  </span>
                </Tooltip>
              </div>
              <Controller
                name="maxDollarIncrease"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      prefix="$"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />
                  );
                }}
              />
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">$</span>
                <h1 className="font-bold mx-2">Min Dollar Increase</h1>
                <Tooltip title="The minimum incremental dollar increase any one tenant may receive.">
                  <span className="material-symbols-rounded text-xl text-primary">
                    help
                  </span>
                </Tooltip>
              </div>
              <Controller
                name="minDollarIncrease"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      prefix="$"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />
                  );
                }}
              />
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">%</span>
                <h1 className="font-bold mx-2">Max Percent Increase</h1>
                <Tooltip title="The maximum percentage increase any one tenant may receive.">
                  <span className="material-symbols-rounded text-xl text-primary">
                    help
                  </span>
                </Tooltip>
              </div>

              <Controller
                name="maxPercentIncrease"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      suffix="%"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />
                  );
                }}
              />
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">%</span>
                <h1 className="font-bold mx-2">Min Percent Increase</h1>
                <Tooltip title="The minimum percentage increase any one tenant may receive.">
                  <span className="material-symbols-rounded text-xl text-primary">
                    help
                  </span>
                </Tooltip>
              </div>

              <Controller
                name="minPercentIncrease"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      suffix="%"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />
                  );
                }}
              />
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">%</span>
                <h1 className="font-bold mx-2">Store Occupancy Threshold</h1>
                <Tooltip title="The minimum occupancy required for any given facility to qualify for tenant rate increases.">
                  <span className="material-symbols-rounded text-xl text-primary">
                    help
                  </span>
                </Tooltip>
              </div>

              <Controller
                name="storeOccupancyThreshold"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      suffix="%"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />
                  );
                }}
              />
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <img
                  src="/assets/images/clock.png"
                  className="h-5"
                  alt="Light Bulb Icon"
                />
                <h1 className="font-bold mx-2">Time Since Last Increase</h1>
                <Tooltip title="The minimum time in months since a tenant's last rate increase.">
                  <span className="material-symbols-rounded text-xl text-primary">
                    help
                  </span>
                </Tooltip>
              </div>

              <div>
                <input
                  type="number"
                  className="w-20 px-2 py-2 border border-gray-700"
                  {...register("timeSinceLastIncrease")}
                />{" "}
                <span className="ml-2">months</span>
              </div>
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <img
                  src="/assets/images/clock.png"
                  className="h-5"
                  alt="Light Bulb Icon"
                />
                <h1 className="font-bold mx-2">Time Since Move-in</h1>
                <Tooltip title="The minimum time in months since a tenant's move-in date.">
                  <span className="material-symbols-rounded text-xl text-primary">
                    help
                  </span>
                </Tooltip>
              </div>

              <div>
                <input
                  type="number"
                  className="w-20 px-2 py-2 border border-gray-700"
                  {...register("timeSinceMoveIn")}
                />{" "}
                <span className="ml-2">months</span>
              </div>
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">$</span>
                <h1 className="font-bold mx-2">Limit Above Street Rate</h1>
                <Tooltip title="The absolute dollar value over the unit street rate a tenant is occupying.">
                  <span className="material-symbols-rounded text-xl text-primary">
                    help
                  </span>
                </Tooltip>
              </div>

              <Controller
                name="limitAboveStreetRate"
                control={control}
                render={({ field: { name, onChange, value, disabled } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      prefix="$"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />
                  );
                }}
              />
            </div>
            <div className="rounded-gray-outlined-box">
              <div className="flex items-center w-96">
                <span className="text-primary font-bold">%</span>
                <h1 className="font-bold mx-2">Max Move-Out Probability</h1>
                <Tooltip title="The desired upper threshold for any given tenant's move-out probability.">
                  <span className="material-symbols-rounded text-xl text-primary">
                    help
                  </span>
                </Tooltip>
              </div>

              <Controller
                name="maxMoveOutProbability"
                control={control}
                render={({ field: { name, onChange, value } }) => {
                  return (
                    <CurrencyInput
                      className="w-20"
                      suffix="%"
                      disableGroupSeparators={true}
                      decimalsLimit={2}
                      value={value}
                      name={name}
                      onValueChange={onChange}
                    />
                  );
                }}
              />
            </div>
          </div>

          <div className="self-end">
            <button
              type="submit"
              className="px-4 px-2 text-primary border-2 border-red-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Dashboard>
  );
};

export default Settings;
